import React from 'react';

import { SectionContainerSC as SectionContainer } from '@components';
import { LogoList } from '@containers';

const TopStatesSection = ({ title, description, mostDangerous, mostSafe }) => {
  return (
    <SectionContainer title={title} description={description} containerProps={{ pb: 40 }}>
      <LogoList subtitle={mostDangerous.title} data={mostDangerous.data} />
      <LogoList subtitle={mostSafe.title} data={mostSafe.data} />
    </SectionContainer>
  );
};

export default TopStatesSection;
